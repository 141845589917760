import React from "react"
import Layout from "../components/Layout"

export default function About() {
  return (
    <Layout>
      <div>
        <h1>About Page</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam
          eveniet hic dolor voluptas modi dolorum quisquam alias aliquam natus
          soluta beatae, eos laudantium, eius quam quidem repellat, sint nisi
          est?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam
          eveniet hic dolor voluptas modi dolorum quisquam alias aliquam natus
          soluta beatae, eos laudantium, eius quam quidem repellat, sint nisi
          est?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam
          eveniet hic dolor voluptas modi dolorum quisquam alias aliquam natus
          soluta beatae, eos laudantium, eius quam quidem repellat, sint nisi
          est?
        </p>
      </div>
    </Layout>
  )
}
